/* styles.css */
.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .popup-content {
    background-color: black;
    color:white;
    padding: 30px;
    text-align: center;
    border-radius: 40px;
  }
  
  button {
    color:black;
    background-color: gray;
    padding: 10px 20px;
    font-size: 16px;
    margin: 10px;
    cursor:crosshair;
    border-radius: 200px;
  }
  
  button:hover {
    background-color: goldenrod;
  }
